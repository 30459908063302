import * as THREE from 'three';

let ExportHelper;




























































































































ExportHelper = THREE.MeshBasicMaterial;



export { ExportHelper as LMVMeshBasicMaterial };