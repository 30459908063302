// Plane.orthoPoint was removed in R87, the polyfilled version for R125 was taken from R86
// https://github.com/mrdoob/three.js/blob/r86/src/math/Plane.js#L117-L124
export const planeOrthoPoint = (plane, point, optionalTarget) => {

  {
    return plane.orthoPoint(point, optionalTarget);
  }







};

export const vector3ApplyProjection = (v, m) => {

  {
    return v.applyProjection(m);
  }





};