import { ToolInterface } from "../../src/tools/ToolInterface";

const GlobalManagerMixin = Autodesk.Viewing.GlobalManagerMixin;

export class ExplodeTool extends ToolInterface {
  constructor(viewer) {var _this;
    super();_this = this;

    this.names = ['explode'];
    this.viewer = viewer;
    this.setGlobalManager(this.viewer.globalManager);
    this.active = false;

    this.activate = () => {
      this.active = true;
    };

    this.deactivate = function () {let reset = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      if (reset)
      _this.setScale(0);
      _this.active = false;
    };

    this.isActive = () => {
      return this.active;
    };
  }

  setScale(value) {
    return this.viewer.explode(value);
  }

  getScale() {
    return this.viewer.getExplodeScale();
  }}


GlobalManagerMixin.call(ExplodeTool.prototype);