import * as THREE from 'three';

let ExportHelper;

















































































ExportHelper = THREE.LineBasicMaterial;



export { ExportHelper as LMVLineBasicMaterial };